@import "../../Lib/Styles/globals";

.liveDemoPopup {
  .PopUp {
    .content {
      // height: 300px;
      overflow: visible;

      @include mobile {
        overflow: auto;
        height: 450px;
      }
    }

    .dotLoaderWrap {
      padding-top: 10px;
      text-align: center;
    }
  }

  .submitSaasLiveDemo {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }

  .saasDateTimePicker {
    .dateSection {
      width: 45%;
      margin-right: 5%;
      display: inline-block;

      @include mobile {
        width: 100%;
        margin: 0;
        padding: 10px 0;
      }

      .label {
        padding-bottom: 5px;
        font-size: $regularBoldSize;
        font-weight: bold;
      }

      .DatePicker input {
        padding: 12px 0;
        padding-left: 10px;
        width: 95%;
        border: solid 1px $grey4;

        @include mobile {
          padding: 12px 0;
          padding-left: 3%;
          width: 97%;
        }
      }

      .DropDown {
        padding: 10px;
        border: solid 1px $grey4;

        @include mobile {
          display: block;
          padding: 12px 10px;
        }

        .activeState {
          width: 140px;

          @include mobile {
            width: 100%;
          }

          &.active {
            .DropdownValue {
              height: 300px;
              overflow-y: auto;

              @include mobile {
                width: 100%;
                height: 250px;
              }
            }
          }
        }
      }
    }
  }
}
