@import "../../Lib/Styles/globals";

.DotLoader,
.DotLoader::after,
.DotLoader::before {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.DotLoader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s !important;
  animation-delay: -0.16s !important;
}

.DotLoader::before {
  left: -2em;
  -webkit-animation-delay: -0.32s !important;
  animation-delay: -0.32s !important;
}

.DotLoader::after {
  left: 2em;
}

.DotLoader::after,
.DotLoader::before {
  content: "";
  position: absolute;
  top: 0;
}

@-webkit-keyframes load7 {
  0%,
  100%,
  80% {
    box-shadow: 0 1em 0 -1.3em $primary;
  }

  40% {
    box-shadow: 0 1em 0 0 $primary;
  }
}

@keyframes load7 {
  0%,
  100%,
  80% {
    box-shadow: 0 1em 0 -1.3em $primary;
  }

  40% {
    box-shadow: 0 1em 0 0 $primary;
  }
}
