@import "../../Lib//Styles/globals";

.DatePicker {
  position: relative;

  .CalendarIcon {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .DayPickerInput {
    width: 100%;

    input {
      width: 100%;
    }

    .DayPickerInput-OverlayWrapper {
      position: relative;
      width: 100%;

      .DayPickerInput-Overlay {
        width: 90%;
        min-width: 250px;
        position: absolute;
        left: 0;
        z-index: 1;
        background: $white;
        border: 1px solid $grey1;
        padding: 5px;
        margin: 0 10px;

        .DayPicker {
          display: block;
          font-size: $regularSize;

          .DayPicker-wrapper {
            position: relative;
            flex-direction: row;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            .DayPicker-NavButton {
              position: absolute;
              display: inline-block;
              top: 0;
              right: 0;
              left: auto;
              margin-top: 0;
              width: 50px;
              height: 50px;
              background-size: 50%;
              background: no-repeat center;
              color: $grey3;
              cursor: pointer;
            }

            .DayPicker-NavButton:hover {
              opacity: 0.8;
            }

            .DayPicker-NavButton--prev {
              background-image: url("https://s3.amazonaws.com/webapp-prod-assets/refonte/assets/previousIcon.svg");
              margin-right: 0;
              left: 25px;
            }

            .DayPicker-NavButton--next {
              background-image: url("https://s3.amazonaws.com/webapp-prod-assets/refonte/assets/nextIcon.svg");
              margin-left: 0;
              right: 25px;
            }

            .DayPicker-NavButton--interactionDisabled {
              display: none;
            }

            .DayPicker-Months {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
            }

            .DayPicker-Month {
              display: table;
              margin-top: 15px;
              border-spacing: 0;
              border-collapse: collapse;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;

              .DayPicker-Caption {
                display: table-caption;
                margin-bottom: 5px;
                padding: 0 10px;
                text-align: left;
              }

              .DayPicker-Caption > div {
                text-align: center;
                text-transform: uppercase;
                font-size: $regularSize;
                font-weight: bold;
              }

              .DayPicker-Weekdays {
                display: table-header-group;
                margin-top: 15px;

                .DayPicker-WeekdaysRow {
                  display: table-row;

                  .DayPicker-Weekday {
                    display: table-cell;
                    padding: 10px;
                    color: $grey3;
                    text-align: center;
                    font-size: $regularSize;
                  }

                  .DayPicker-Weekday abbr[title] {
                    border-bottom: none;
                    text-decoration: none;
                  }
                }
              }

              .DayPicker-Body {
                display: table-row-group;

                .DayPicker-Week {
                  display: table-row;

                  .DayPicker-Day {
                    display: table-cell;
                    padding: 10px;
                    border-radius: 100%;
                    vertical-align: middle;
                    text-align: center;
                    cursor: pointer;
                    font-size: $smallSize;

                    .DayPicker-Day--disabled {
                      color: $grey4;
                      cursor: not-allowed;
                    }

                    .DayPicker-Day--today {
                      color: $grey3;
                      font-weight: 700;
                    }
                  }

                  .DayPicker-Day--outside {
                    color: $grey4;
                  }

                  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
                    position: relative;
                    background-color: $secondary;
                    color: $white;
                  }

                  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
                    background-color: $grey4;
                  }
                }
              }
            }
          }

          .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
            background-color: $grey4;
          }
        }
      }
    }
  }
}
