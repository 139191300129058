@import "./globals";

html {
  font-size: 12px;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  color: $grey1;
  font-size: $regularSize;
  line-height: 1.5;
  font-family: Montserrat-Medium, sans-serif;
  font-display: swap;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }
}

input,
button {
  font-family: Montserrat-Medium, sans-serif;
}

body div, body article {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul li {
  margin: 0;
  padding: 0;
}

ol {
  margin: 0;
  padding: 0;
}

p {
  padding: 0;
  margin: 0;
  // @include rem("margin", 0, 0, 5, 0);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  // @include rem("margin", 0, 0, 10, 0);

  font-weight: normal;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

.button-reset {
  padding: 0;
  border: 0;
  background: none;
  margin: 0;
  -webkit-appearance: none;
  outline: 0;
  cursor: pointer;
  text-decoration: none;
}

.button-reset:focus,
.button-reset:active {
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

// %clearfix {
//   @include clearfix();
// }
