@import "../../Lib/Styles/globals";

.Button {
  cursor: pointer;
  display: inline-block;
  transition: all ease 0.3s;
  font-size: $smallSize;
  text-transform: capitalize;
  border-radius: 2px;

  .subText {
    font-size: 9px;
  }
}

.FlatButton {
  background-color: transparent;
  border: 0;
  padding: 11px;

  &:hover {
    color: $primary;
  }
}

.NormalButton {
  background-color: transparent;
  border: solid 1px $black;
  padding: 12px 18px;
  text-transform: uppercase;

  &:hover {
    background-color: $white;
    border-color: $brandTeal;
    color: $primary;
  }

  &.active {
    background-color: $secondary;
    border-color: $secondary;
    color: $white;
  }
}

.PrimeButton {
  background-color: $primary;
  border: 1px solid $primary;
  color: $white;
  padding: 12px 18px;
  text-transform: uppercase;

  &:hover {
    background-color: $darkGreen;
    border-color: $darkGreen;
  }

  &.disabled {
    &:hover {
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.round {
  border-radius: 20px;
}
