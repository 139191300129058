@import "../../Lib//Styles/globals";

.PopUp {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 16;
  top: 0;
  left: 0;
  padding-top: 4%;
  background-color: $overlayBg;
  animation: fadeIn 0.5s ease-in-out both;
  text-align: left;

  @include mobile {
    padding-top: 0;
  }

  .smallPop {
    max-width: 400px !important;

    @include mobile {
      max-width: 100% !important;
    }
  }

  .normalPop {
    max-width: 800px !important;

    @include mobile {
      max-width: 100% !important;
    }
  }

  .largePop {
    max-width: 1200px !important;

    @include mobile {
      max-width: 100% !important;
    }
  }

  .close {
    width: 30px;
    height: 30px;
    padding: 0;
    position: absolute;
    top: 6px;
    right: 4px;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
  }

  .PopUpContent {
    width: 100%;
    max-width: $maxWidth;
    background-color: $white;
    margin: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    position: relative;

    @include mobile {
      position: absolute;
      left: 0;
      bottom: 0;
      border-radius: 0;
      box-shadow: none;
    }

    .header {
      &.withContent {
        background-color: $grey6;
        padding: 15px 20px;
      }

      .new-tag {
        margin-left: 10px;
        display: inline-block;
        background: $brown;
        border-radius: 2px;
        color: $white;
        font-size: 8px;
        padding: 0 5px;
        line-height: 14px;
        text-transform: uppercase;
        position: absolute;
        top: 4px;
      }

      position: relative;

      .main {
        font-size: 15px;
        padding-bottom: 4px;
        padding-right: 10px;
        position: relative;
      }

      .sub {
        font-size: 13px;
      }
    }

    .content {
      padding: 20px;
      max-height: 80vh;
      overflow-y: auto;

      .closeBtn {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}
