@import "./globals";

body {
  .visible-xs,
  .visible-inline-xs {
    display: none !important;
  }

  .visible-xl,
  .visible-inline-xl {
    display: none !important;
  }

  .hidden {
    display: none !important;
  }

  @media (min-width: ($mobileWidth+1)+px) {
    //desktop
    .visible-xl {
      display: block !important;
    }

    .visible-inline-xl {
      display: inline-block !important;
    }

    .hidden-xl,
    .hidden-inline-xl {
      display: none !important;
    }
  }

  @media (max-width: $mobileWidth+px) {
    //mobile
    .visible-xs {
      display: block !important;
    }

    .visible-inline-xs {
      display: inline-block !important;
    }

    .hidden-xs,
    .hidden-inline-xs {
      display: none !important;
    }
  }
}
