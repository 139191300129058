@import "../../Lib/Styles/globals";

.landingFooter {
  padding: 50px 0;
  text-align: center;
  color: $grey2;
  border-top: solid 1px $grey5;
  @include mobile {
    padding: 20px 0;
  }

  .image {
    height: 30;
    width: 121;
    background-color: yellow;
    border-top: solid 1px $grey5;
    @include mobile {
      padding: 20px 0;
    }
  }
}