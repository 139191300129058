// ----------------------------------------------------------------------

// Mobile Media Query

// ----------------------------------------------------------------------

// example: @include mobile {font-size: 18px;}

$smallMobileWidth: 360;
$mobileWidth: 980;
$desktopWidth: 1441;

@mixin small {
  // this should always come after @include mobile()
  @media (max-width: $smallMobileWidth+px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobileWidth+px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktopWidth+px) {
    @content;
  }
}
