@import "../../Lib/Styles/globals";

$moveRightPx: 60px;

.inputContainer {
  position: relative;
  margin-bottom: 5px;

  input {
    display: block;
    width: 100%;
    padding: 12px;
    font-size: 13px;
    border: solid 1px $grey2;
    box-sizing: border-box;
    border-radius: 1px;
    margin: 0;

    &:focus {
      outline: none;
      border: 1px solid $darkGreen;

      + .floatingLabel {
        opacity: 1;
        top: -5px;
        color: $grey3;

        &.moveRight {
          left: $moveRightPx;
        }
      }
    }
  }

  &.errorContainer {
    input {
      border-color: $alert;
      animation:
        shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955)
        both;
    }

    &.countryCode {
      &::before {
        animation:
          shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955)
          both;
      }
    }
  }

  .floatingLabel {
    position: absolute;
    pointer-events: none;
    top: 5px;
    left: 10px;
    font-size: 10px;
    opacity: 0;
    background-color: $white;
    padding: 0 2px;
    transition: 0.2s ease-in-out;
    z-index: 2;

    &.fixLabel {
      top: -5px;
      opacity: 1;
      color: $grey3;
    }

    &.moveRight {
      left: $moveRightPx;
    }
  }

  .error {
    color: $alert;
    background-color: $white;
    font-size: 9px;
    border: 1px solid $alert;
    padding: 2px 4px;
    border-radius: 2px;
    position: absolute;
    z-index: 1;
    right: 3px;
    bottom: -9px;
  }

  &.countryCode {
    position: relative;

    &::before {
      content: "+91";
      position: absolute;
      top: 1px;
      left: 1px;
      z-index: 1;
      height: 40px;
      width: 40px;
      background-color: $grey5;
      color: $grey3;
      line-height: 40px;
      text-align: center;
      border-right: 1px solid $grey3;
      cursor: not-allowed;
    }

    input {
      padding-left: 52px;
      border-right: solid 1px $grey2;
    }
  }

  &.selectCountryCodes {
    input {
      padding-left: 70px;
    }

    .DropDown {
      position: absolute;
      top: 11px;
      left: 5px;
    }

    input.countryCodeBox {
      width: 50px;
      position: absolute;
      left: 0;
      top: 0;
      padding-left: 14px;
      padding-right: 5px;
      z-index: 1;
    }

    .plusSign {
      width: auto;
      position: absolute;
      left: 6px;
      top: 12px;
      z-index: 2;
    }

    .countrySelected {
      width: auto;
      position: absolute;
      left: 0;
      top: -20px;
    }
  }
}
