@import "../../Lib/Styles/globals";

.DropDown {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;

  svg {
    margin-left: 4px;
  }

  label {
    padding-right: 6px;
    color: $grey2;
  }

  .activeState {
    display: inline-block;
    color: $black;
    width: 170px;

    .dropdown-text {
      width: 90%;
      display: inline-block;
    }
  }

  .DropdownValue {
    min-width: 200px;
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 10;
    text-align: left;
    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.15) 0 14px 36px 2px;
    border-radius: 0 0 3px 3px;
    overflow-y: auto;

    @include mobile {
      max-height: 350px;
    }

    label {
      width: 100%;
      font-size: 12px;
      padding: 12px;
      color: $grey2;
      display: inline-block;
      vertical-align: middle;
      margin-left: 4px;
    }

    li {
      padding: 13px 15px;
      transition: background-color ease 0.3s;

      &:hover {
        background-color: $grey5;
        cursor: pointer;
      }

      &.active {
        background-color: $secondary;
        color: $white;
      }

      svg,
      .text {
        display: inline-block;
        vertical-align: middle;
        margin-left: 0;
      }

      .text {
        margin-left: 4px;
        color: $grey2;
      }
    }
  }

  &.growTop {
    .DropdownValue {
      bottom: 28px;
      top: auto;
      right: auto;
      left: 0;
    }
  }
}
