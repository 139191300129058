@import "./mixins";

@keyframes blurring {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideDown {
  0% {
    @include translateY(-100%);
  }

  100% {
    @include translateY(0%);
  }
}

@keyframes slideUp {
  0% {
    @include translateY(0%);
  }

  100% {
    @include translateY(-100%);
  }
}

@keyframes slideUpEntry {
  0% {
    @include translateY(200%);
  }

  100% {
    @include translateY(0%);
  }
}

@keyframes slideUpExit {
  0% {
    @include translateY(0%);
  }

  100% {
    @include translateY(200%);
  }
}

@keyframes slideDownExit {
  0% {
    @include translateY(0%);
  }

  100% {
    @include translateY(-200%);
  }
}

@keyframes slideLeft {
  0% {
    @include translateX(100%);
  }

  100% {
    @include translateY(0%);
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0.75) translateY(10%);
  }

  100% {
    transform: scale(1) translateY(0%);
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1.075);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(90deg);
  }
}

@keyframes rotateBack {
  0% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    @include translateX(0);
  }

  10%,
  30%,
  50%,
  70% {
    @include translateX(-10px);
  }

  20%,
  40%,
  60% {
    @include translateX(10px);
  }

  80% {
    @include translateX(8px);
  }

  90% {
    @include translateX(-8px);
  }
}

@keyframes shake-horizontal {
  0%,
  100% {
    @include translateX(0);
  }

  10%,
  30%,
  50%,
  70% {
    @include translateX(-10px);
  }

  20%,
  40%,
  60% {
    @include translateX(10px);
  }

  80% {
    @include translateX(8px);
  }

  90% {
    @include translateX(-8px);
  }
}

@keyframes balloons {
  0% {
    transform: translateY(0) rotate(2deg);
  }

  25% {
    transform: translateY(3px) rotate(-2deg);
  }

  50% {
    transform: translateY(6px) rotate(2deg);
  }

  75% {
    transform: translateY(3px) rotate(-2deg);
  }

  100% {
    transform: translateY(0) rotate(2deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;

  &.circle {
    stroke-dashoffset: 1000;
    animation: dash 0.9s ease-in-out;
  }

  &.line {
    stroke-dashoffset: 1000;
    animation: dash 0.9s 0.35s ease-in-out forwards;
  }

  &.check {
    stroke-dashoffset: -100;
    animation: dash-check 0.9s 0.35s ease-in-out forwards;
  }
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}

@-webkit-keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }

  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }

  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }

  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }

  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }

  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }

  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }

  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }

  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}

// This is for success boom effects

.particlesAnim > .after,
.particlesAnim > .before {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  box-shadow:
    0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
    0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
    0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
    0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
    0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
    0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
    0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF,
    0 0 #FFF,
 0 0 #FFF,
 0 0 #FFF;
  -moz-animation:
    1s bang ease-out 0.5s 3 backwards,
    1s gravity ease-in 0.5s 3 backwards,
 5s position linear 0.5s 3 backwards;
  -webkit-animation:
    1s bang ease-out 0.5s 3 backwards,
    1s gravity ease-in 0.5s 3 backwards,
 5s position linear 0.5s 3 backwards;
  -o-animation:
    1s bang ease-out 0.5s 3 backwards,
    1s gravity ease-in 0.5s 3 backwards,
 5s position linear 0.5s 3 backwards;
  -ms-animation:
    1s bang ease-out 0.5s 3 backwards,
    1s gravity ease-in 0.5s 3 backwards,
 5s position linear 0.5s 3 backwards;
  animation:
    1s bang ease-out 0.5s 3 backwards,
    1s gravity ease-in 0.5s 3 backwards,
 5s position linear 0.5s 3 backwards;
}

.particlesAnim > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  to {
    box-shadow:
      -194px -123.33333px #004DFF,
 -26px -290.33333px #FF006F,
      -58px 20.66667px #003CFF,
 184px -243.33333px #F50,
      -172px -133.33333px #00FF6A,
 173px -146.33333px #05F,
      74px -234.33333px #D5FF00,
 199px -124.33333px #0F2,
      87px 42.66667px #4DFF00,
 154px -45.33333px #5F0,
 26px -206.33333px #F04,
      -19px -131.33333px #FF003C,
 138px -52.33333px #3C00FF,
      -104px -198.33333px #FFD000,
 38px -107.33333px #00FF62,
      -168px -325.33333px #51FF00,
 20px 46.66667px #95FF00,
      71px -285.33333px #00FFE6,
 139px -56.33333px #FF5100,
      43px -266.33333px #FFF700,
 -176px -243.33333px #FFFB00,
      22px 60.66667px #00FF15,
 -114px -157.33333px #00FF80,
      51px 7.66667px #FF0080,
 115px -210.33333px #00BFFF,
      90px 44.66667px #00FF73,
 -40px -66.33333px #FF003C,
      154px -98.33333px #00FF7B,
 -182px -231.33333px #FFA600,
      176px -232.33333px #6F00FF,
 -156px -304.33333px #FD0,
      -196px 43.66667px #A200FF,
 -25px -78.33333px #3F0,
 134px -18.33333px blue,
      65px -151.33333px #FFC400,
 25px -17.33333px #7BFF00,
      -144px -239.33333px #F200FF,
 -100px 30.66667px #04FF00,
      -88px 38.66667px #008CFF,
 -143px 56.66667px #FF00D0,
      6px 23.66667px #FF0D00,
 39px -84.33333px #7BFF00,
      50px -300.33333px #FF0015,
 105px 35.66667px #F09,
 53px 36.66667px #FF00D9,
      -173px -278.33333px #2F0,
 -26px -321.33333px #1A00FF,
      167px -135.33333px #FF6200,
 -187px 63.66667px #3CFF00,
      -15px -160.33333px #F30,
 -79px -71.33333px #3700FF;
  }
}

@-moz-keyframes bang {
  to {
    box-shadow:
      -194px -123.33333px #004DFF,
 -26px -290.33333px #FF006F,
      -58px 20.66667px #003CFF,
 184px -243.33333px #F50,
      -172px -133.33333px #00FF6A,
 173px -146.33333px #05F,
      74px -234.33333px #D5FF00,
 199px -124.33333px #0F2,
      87px 42.66667px #4DFF00,
 154px -45.33333px #5F0,
 26px -206.33333px #F04,
      -19px -131.33333px #FF003C,
 138px -52.33333px #3C00FF,
      -104px -198.33333px #FFD000,
 38px -107.33333px #00FF62,
      -168px -325.33333px #51FF00,
 20px 46.66667px #95FF00,
      71px -285.33333px #00FFE6,
 139px -56.33333px #FF5100,
      43px -266.33333px #FFF700,
 -176px -243.33333px #FFFB00,
      22px 60.66667px #00FF15,
 -114px -157.33333px #00FF80,
      51px 7.66667px #FF0080,
 115px -210.33333px #00BFFF,
      90px 44.66667px #00FF73,
 -40px -66.33333px #FF003C,
      154px -98.33333px #00FF7B,
 -182px -231.33333px #FFA600,
      176px -232.33333px #6F00FF,
 -156px -304.33333px #FD0,
      -196px 43.66667px #A200FF,
 -25px -78.33333px #3F0,
 134px -18.33333px blue,
      65px -151.33333px #FFC400,
 25px -17.33333px #7BFF00,
      -144px -239.33333px #F200FF,
 -100px 30.66667px #04FF00,
      -88px 38.66667px #008CFF,
 -143px 56.66667px #FF00D0,
      6px 23.66667px #FF0D00,
 39px -84.33333px #7BFF00,
      50px -300.33333px #FF0015,
 105px 35.66667px #F09,
 53px 36.66667px #FF00D9,
      -173px -278.33333px #2F0,
 -26px -321.33333px #1A00FF,
      167px -135.33333px #FF6200,
 -187px 63.66667px #3CFF00,
      -15px -160.33333px #F30,
 -79px -71.33333px #3700FF;
  }
}

@keyframes bang {
  to {
    box-shadow:
      -194px -123.33333px #004DFF,
 -26px -290.33333px #FF006F,
      -58px 20.66667px #003CFF,
 184px -243.33333px #F50,
      -172px -133.33333px #00FF6A,
 173px -146.33333px #05F,
      74px -234.33333px #D5FF00,
 199px -124.33333px #0F2,
      87px 42.66667px #4DFF00,
 154px -45.33333px #5F0,
 26px -206.33333px #F04,
      -19px -131.33333px #FF003C,
 138px -52.33333px #3C00FF,
      -104px -198.33333px #FFD000,
 38px -107.33333px #00FF62,
      -168px -325.33333px #51FF00,
 20px 46.66667px #95FF00,
      71px -285.33333px #00FFE6,
 139px -56.33333px #FF5100,
      43px -266.33333px #FFF700,
 -176px -243.33333px #FFFB00,
      22px 60.66667px #00FF15,
 -114px -157.33333px #00FF80,
      51px 7.66667px #FF0080,
 115px -210.33333px #00BFFF,
      90px 44.66667px #00FF73,
 -40px -66.33333px #FF003C,
      154px -98.33333px #00FF7B,
 -182px -231.33333px #FFA600,
      176px -232.33333px #6F00FF,
 -156px -304.33333px #FD0,
      -196px 43.66667px #A200FF,
 -25px -78.33333px #3F0,
 134px -18.33333px blue,
      65px -151.33333px #FFC400,
 25px -17.33333px #7BFF00,
      -144px -239.33333px #F200FF,
 -100px 30.66667px #04FF00,
      -88px 38.66667px #008CFF,
 -143px 56.66667px #FF00D0,
      6px 23.66667px #FF0D00,
 39px -84.33333px #7BFF00,
      50px -300.33333px #FF0015,
 105px 35.66667px #F09,
 53px 36.66667px #FF00D9,
      -173px -278.33333px #2F0,
 -26px -321.33333px #1A00FF,
      167px -135.33333px #FF6200,
 -187px 63.66667px #3CFF00,
      -15px -160.33333px #F30,
 -79px -71.33333px #3700FF;
  }
}

@-moz-keyframes gravity {
  to {
    transform: translateY(180px);
    -moz-transform: translateY(180px);
    -webkit-transform: translateY(180px);
    -o-transform: translateY(180px);
    -ms-transform: translateY(180px);
    opacity: 0;
  }
}

@keyframes gravity {
  to {
    transform: translateY(180px);
    -moz-transform: translateY(180px);
    -webkit-transform: translateY(180px);
    -o-transform: translateY(180px);
    -ms-transform: translateY(180px);
    opacity: 0;
  }
}

@-moz-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
}

@keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@keyframes bounceTop {
  0% {
    transform: translateY(-45px);
    animation-timing-function: ease-in;
    opacity: 1;
  }

  24% {
    opacity: 1;
  }

  40% {
    transform: translateY(-24px);
    animation-timing-function: ease-in;
  }

  65% {
    transform: translateY(-12px);
    animation-timing-function: ease-in;
  }

  82% {
    transform: translateY(-6px);
    animation-timing-function: ease-in;
  }

  93% {
    transform: translateY(-4px);
    animation-timing-function: ease-in;
  }

  25%,
  55%,
  75%,
  87% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }

  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@mixin transition200 {
  transition: transform 200ms;
}

.rotateLeft {
  @include transition200;

  transform: rotateZ(90deg);
}

.rotateRight {
  @include transition200;

  transform: rotateZ(-90deg);
}

.rotateDown {
  @include transition200;

  transform: rotateX(0deg);
}

.rotateUp {
  @include transition200;

  transform: rotate(180deg);
}

@-webkit-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

// @-webkit-keyframes Textchange {
//   0%,
//   12.66%,
//   100% {
//     transform: translate3d(0, 0, 0);
//   }

//   16.66%,
//   29.32% {
//     transform: translate3d(0, -25%, 0);
//   }

//   33.32%,
//   45.98% {
//     transform: translate3d(0, -50%, 0);
//   }

//   49.98%,
//   62.64% {
//     transform: translate3d(0, -75%, 0);
//   }

//   66.64%,
//   79.3% {
//     transform: translate3d(0, -50%, 0);
//   }

//   83.3%,
//   95.96% {
//     transform: translate3d(0, -25%, 0);
//   }
// }

@keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes Textchange1 {
  0% {
    transform: translate(0, 100%);
  }

  8.33% {
    transform: translate(0, 0%);
  }

  16.66% {
    transform: translate(0, 0%);
  }

  25% {
    transform: translate(0, 0%);
  }

  33.33% {
    transform: translate(0, -100%);
  }

  66.66% {
    transform: translate(0, -100%);
  }

  100% {
    transform: translate(0, -100%);
  }
}

@keyframes Textchange2 {
  0% {
    transform: translate(0, 100%);
  }

  33.33% {
    transform: translate(0, 100%);
  }

  41.66% {
    transform: translate(0, 0%);
  }

  49.99% {
    transform: translate(0, 0%);
  }

  58.32% {
    transform: translate(0, 0%);
  }

  66.66% {
    transform: translate(0, -100%);
  }

  100% {
    transform: translate(0, -100%);
  }
}

@keyframes Textchange3 {
  0% {
    transform: translate(0, 100%);
  }

  66.66% {
    transform: translate(0, 100%);
  }

  74.99% {
    transform: translate(0, 0%);
  }

  83.32% {
    transform: translate(0, 0%);
  }

  91.65% {
    transform: translate(0, 0%);
  }

  100% {
    transform: translate(0, -100%);
  }
}

@-webkit-keyframes scaleout {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1.5);
    opacity: 0;
  }
}

@-moz-keyframes scaleout {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes scaleout {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1.5);
    opacity: 0;
  }
}

@-webkit-keyframes scale-in-bottom {
  0% {
    transform: scale(0);
    transform-origin: 50% 100%;
    opacity: 1;
  }

  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
    opacity: 1;
  }
}

@keyframes scale-in-bottom {
  0% {
    transform: scale(0);
    transform-origin: 50% 100%;
    opacity: 1;
  }

  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
    opacity: 1;
  }
}

.forward {
  -webkit-animation: slideright 0.5s forwards;
  -webkit-animation-delay: 0s;
  animation: slideright 0.5s forwards;
  animation-delay: 0s;
}

@-webkit-keyframes slideright {
  100% {
    right: 0;
  }
}

@keyframes slideright {
  100% {
    right: 0;
  }
}

@keyframes lypShade {
  0% {
    transform: skewX(-20deg) translateX(-20px);
  }

  100% {
    transform: skewX(-20deg) translateX(200px);
  }
}
