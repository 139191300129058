// ----------------------------------------------------------------------

// Translate

// ----------------------------------------------------------------------

// example: @include translate(0);

@mixin translateX($value) {
  -webkit-transform: translateX($value);
  -moz-transform: translateX($value);
  -ms-transform: translateX($value);
  -o-transform: translateX($value);
  transform: translateX($value);
}
